import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import ArrowLink from '../../common/arrow-link'
import CarThumbnail from '../../common/car-thumbnail'
import { HOMEPAGE_DEALS_SLIDER_SETTINGS } from '../../../constants'
import GlideSlider from '../../common/glideSlider'
import styles from './deals.module.scss'
import cx from 'classnames'
import { formatPrice } from '../../../utils/functions/miscellaneous'

/**
 * DealsModels component
 */
const DealsModels = ({ data = [] }) => {
  if (isEmpty(data) || !Array.isArray(data)) {
    return null
  }

  const getPriceRange = (range) => {
    if (isEmpty(range)) {
      return ''
    }
    return `${formatPrice(range?.min, true)} - ${formatPrice(range?.max, true)}`
  }
  // FIX: Global CSS
  return (
    <div className={cx(styles['drive-home-deals'], 'lg:container bg-gray-200-screen ')}>
      <div className={cx(styles['drive-home-deals__header'])}>
        <h2 className={cx(styles['drive-home-deals__title'])}>Drive’s Weekly Deals</h2>
        <div className={cx(styles['drive-home-deals__title-wrapper'])}>
          <ArrowLink
            link='/showrooms/deals/'
            linkText='See all New Car Deals'
            className={cx(styles['drive-home-deals__title-link'])}
            isExternal={false}
          />
        </div>
      </div>
      <div className={cx(styles['drive-home-deals__slider'])}>
        <GlideSlider glideOptions={HOMEPAGE_DEALS_SLIDER_SETTINGS}>
          {data?.map((carModel, index) => {
            const priceRange = getPriceRange(carModel?.priceRange)
            const uri = carModel?.uri ? `${carModel?.uri}#model-deals` : ''
            return (
              <div key={carModel?.id}>
                <CarThumbnail
                  className={cx(styles['drive-home-deals__thumbnail'])}
                  isJumpLinkOrHashFragment
                  modelUri={uri}
                  makeLogoUrl={carModel?.logo?.sourceUrl}
                  title={carModel?.title}
                  priceRange={priceRange}
                  variantCount={carModel?.variantsCount}
                  featuredImage={carModel?.featuredImage}
                  makeLogo={carModel?.logo}
                  sizeName='DEALS_CARD'
                  makeLogoSizeName='DEAL_CARD_MAKE_LOGO'
                  isDealsPage //true for showing deals count
                  validDealsCount={carModel?.dealsCount}
                  imageAttributes={{
                    loading: 'lazy',
                    fetchpriority: 'low',
                    width: 272,
                    height: 155
                  }}
                />
              </div>
            )
          })}
        </GlideSlider>
      </div>
    </div>
  )
}

DealsModels.propTypes = {
  data: PropTypes.array
}

export default DealsModels
