import cx from 'classnames'
import { Color } from '../../../constants/colors'
import { ChevronDown, ChevronUp } from '../../icons'
import styles from './curated-car-list.module.scss'

const DropdownIndicatorArrow = () => {
  return (
    <span>
      <ChevronUp
        height='12'
        color={Color.black}
        className={cx(styles['d-curated-carlist__tabs-wrapper__chevron-up'])}
      />
      <ChevronDown
        height='12'
        color={Color.black}
        className={cx(styles['d-curated-carlist__tabs-wrapper__chevron-down'])}
      />
    </span>
  )
}

export default DropdownIndicatorArrow
