import cx from 'classnames'
import PropTypes from 'prop-types'
import Link from '../common/link'
import CarThumbnail from '../cars-for-sale/cars-list/car-thumbnail'
import { parseCarsForSaleData } from '../../utils/functions/listings'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import styles from './home.module.scss'
import { useEffect, useState } from 'react'
import { GET_HOME_LISTINGS_CLIENT } from '../../queries/home/get-home-listing'
import { useQuery } from '@apollo/client'
import { useGeoLocation } from '@grille/utils/hooks/useGeoLocation'
import { GEO_APPLICABLE_STATES } from '@grille/constants/pages'

const CarForSale = (props) => {
  const { listingType = 'new' } = props || {}
  const [carsList, setCarsList] = useState({
    results: parseCarsForSaleData(props?.cars?.results, [], props?.carsToShow),
    hasGeoFetched: false
  })

  const { refetch } = useQuery(GET_HOME_LISTINGS_CLIENT, {
    context: { listing: true },
    skip: true
  })
  const { onLoaded: onGeoLoaded } = useGeoLocation()
  useEffect(() => {
    const updateGeoCarsResults = async (geoData) => {
      const whereOptionsDealerListing =
        listingType === 'new'
          ? {
              stockType: { in: ['New', 'Demo'] },
              odometer: { lt: 5000 }
            }
          : {
              stockType: { eq: 'Used' }
            }

      // only if geo data is present and country is Australia
      if (typeof geoData !== 'undefined' && geoData?.countryCode === 'AU') {
        // only if current geo state falls under applicable states
        if (GEO_APPLICABLE_STATES.indexOf(geoData?.state ?? '') !== -1) {
          whereOptionsDealerListing.state = { eq: geoData.state }
        }
      }

      const { data: resultsData, errors } = await refetch({
        sort: { order: [['recommended', 'DESC']] },
        imageCount: { gte: 5 },
        limit: 5,
        whereOptionsDealerListing: whereOptionsDealerListing
      })

      if (isEmpty(errors)) {
        setCarsList({
          results: parseCarsForSaleData(
            resultsData?.dealerListings?.results ?? [],
            [],
            props?.carsToShow
          ),
          hasGeoFetched: true
        })
      }
    }

    /* handle and update carsList when geoLocation loads */
    onGeoLoaded((geoData) => {
      !carsList.isGeoBasedListing && updateGeoCarsResults(geoData)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isEmpty(carsList.results) || !isArray(carsList.results)) {
    return null
  }

  return (
    <div className={cx(styles['drive-home-cfs'], props.className)}>
      <Link href={props?.titleUrl} isJumpLinkOrHashFragment>
        <a className={cx(styles['drive-home-cfs__link'])}>{props?.title}</a>
      </Link>
      <div className={cx(styles['drive-home-cfs__wrapper'])}>
        {carsList.results?.length
          ? carsList.results.map((car, index) => (
              <CarThumbnail
                imageLoading={index < 2 ? 'eager' : 'lazy'}
                key={index}
                car={car}
                smallWidth={props.smallWidth}
                isCarsForSaleDefaultImage
                imageAttributes={{
                  loading: 'lazy',
                  width: 202,
                  height: 113,
                  fetchpriority: 'low'
                }}
              />
            ))
          : null}
      </div>
      {props.bottomLinkText && props.bottomLinkUrl ? (
        <Link href={props.bottomLinkUrl} isJumpLinkOrHashFragment>
          <a className={cx(styles['drive-home-cfs__bottom-link'])}>{props.bottomLinkText}</a>
        </Link>
      ) : null}
    </div>
  )
}

CarForSale.propsType = {
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  className: PropTypes.string,
  cars: PropTypes.array.isRequired,
  articles: PropTypes.array.isRequired,
  bottomLinkText: PropTypes.string,
  bottomLinkUrl: PropTypes.string
}

export default CarForSale
