import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { DealsDollarSignIcon } from '../../../icons'
import { useState } from 'react'
import { getSingularOrPluralText } from '../../../../utils/functions/miscellaneous'
import Tooltip from '../../../common/tooltip/index'
import styles from './dealsInfoWithTooltip.module.scss'
import cx from 'classnames'
import { Color } from '../../../../constants/colors'
import Link from '../../link'

const DealsInfoWithToolTip = ({ deals = [], modelUri, validDealsCount = 0 }) => {
  const [showToolTip, setShowToolTip] = useState(false)

  if ((!deals || isEmpty(deals)) && !validDealsCount) {
    return null
  }

  const numberOfDeals = validDealsCount || deals.length
  const toolTipText = numberOfDeals + ' ' + getSingularOrPluralText(numberOfDeals, 'Current Deal')
  return (
    <span
      data-testid='tooltip'
      className={cx(styles['drive-deals-info-tooltip__container'])}
      onMouseOver={() => {
        setShowToolTip(true)
      }}
      onMouseLeave={() => {
        setShowToolTip(false)
      }}
      onClick={() => {
        // For mobile tap event
        setShowToolTip(true)
      }}
    >
      <DealsDollarSignIcon color={Color.white} className='absolute' />
      {showToolTip && (
        <Link href={modelUri} isJumpLinkOrHashFragment={true}>
          <a href={modelUri} data-cy='CarThumbnail-permalink'>
            <Tooltip text={toolTipText} />
          </a>
        </Link>
      )}
    </span>
  )
}

DealsInfoWithToolTip.propTypes = {
  deals: PropTypes.array,
  validDealsCount: PropTypes.number
}

export default DealsInfoWithToolTip
