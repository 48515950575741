import { useCallback, useContext, useRef } from 'react'
import useMobileAdsBehavior from './../useMobileAdsBehavior'
import { PageContext } from '../../../utils/contexts/pageContext'

/**
 * stickyCallback is called when it is in view
 */
const useSpecificAd = () => {
  /**
   * @DeveloperWarning hasIntersected state is introduced to stop abusing updating global page store.
   * @reason without hasIntersected, the updateStore runs in initialLoad which is not necessary as its may not even be visible
   * @info with this change page performance on repaint significantly improved
   * @type {updateStore} is expensive and it triggers rerender the whole application.
   * @type {updateStore} should only be used if its necessary when many components needs change at once
   * @TODO change the current logic that uses updateStore method instead use PubSub to trigger sticky and non sticky in the techDebt sprint
   */
  const hasIntersected = useRef(false)
  /** @DeveloperWarning end */

  const { updateStore, pageStore } = useContext(PageContext)
  const [intersectChange] = useMobileAdsBehavior({
    refsArray: [],
    refCount: 0,
    isNewImplementation: true
  })
  const refs = useRef<{}[]>([])

  // use callback ref to detect ref change
  const adsRefChange = useCallback(
    (element: HTMLElement, adId: string) => {
      if (element && process.browser && window.innerWidth < 768) {
        let refObject = {}
        switch (adId) {
          case 'gam_inreads_pos2':
            refObject = {
              ref: element || null,
              name: adId,
              stickyCallback: () => {
                if (pageStore?.hideRoofrackAd === false) {
                  hasIntersected.current = true
                  updateStore('hideRoofrackAd', true)
                }
              },
              aboveElementCallback: () => {
                if (pageStore?.hideRoofrackAd === true && hasIntersected.current) {
                  updateStore('hideRoofrackAd', false)
                }
              }
            }
            break

          case 'gam_inreads_pos3':
            refObject = {
              ref: element || null,
              name: adId,
              stickyCallback: () => {
                if (pageStore?.hideRoofrackAd === false) {
                  hasIntersected.current = true
                  updateStore('hideRoofrackAd', true)
                }
              },
              aboveElementCallback: () => {}
            }
            break

          case 'gam_inreads_pos4': //mrec
            refObject = {
              ref: element || null,
              name: adId,
              stickyCallback: () => {
                hasIntersected.current = true
                updateStore('isInreadsPos3Sticky', false)
              },
              aboveElementCallback: () => {
                if (hasIntersected.current) {
                  updateStore('isInreadsPos3Sticky', true)
                }
              }
            }
            break

          case 'gam_inreads_home_pos5':
            refObject = {
              ref: element || null,
              name: adId,
              stickyCallback: () => {
                if (pageStore?.hideRoofrackAd === false) {
                  hasIntersected.current = true
                  updateStore('hideRoofrackAd', true)
                }
              },
              aboveElementCallback: () => {}
            }
            break
          case 'gam_inreads_pos6':
            refObject = {
              ref: element || null,
              name: adId,
              stickyCallback: () => {
                if (
                  pageStore?.homepagePos5InreadSticky === true ||
                  pageStore?.homepagePos5InreadSticky === undefined
                ) {
                  hasIntersected.current = true
                  updateStore('homepagePos5InreadSticky', false)
                }
              },
              aboveElementCallback: () => {
                if (pageStore?.homepagePos5InreadSticky === false && hasIntersected.current) {
                  updateStore('homepagePos5InreadSticky', true)
                }
              }
            }
            break
        }
        refs.current.push(refObject)

        intersectChange(refs)
      }
    },
    [intersectChange, pageStore?.hideRoofrackAd, pageStore?.homepagePos5InreadSticky, updateStore]
  )

  return [adsRefChange]
}

export default useSpecificAd
