import { gql } from '@apollo/client'

/**
 * Query to get Makes Options
 */
export const GET_TAILPIPE_MAKES = gql`
  query GET_MAKES($page: Int!, $perPage: Int!, $query: WhereOptionsMake) {
    makes: Makes(paginate: { page: $page, pageSize: $perPage }, where: $query) {
      results {
        id
        label: description
        value: makeableUuid
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
