const customStyles = {
  control: (provided: any, _state: any) => ({
    ...provided,
    '&:hover': {
      borderColor: 'black'
    }
  }),

  dropdownIndicator: (provided: any, _state: any) => ({
    ...provided,
    ':hover': {
      borderColor: 'black'
    }
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused && '#F7F7F7'
  })
}

export default customStyles
