import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import CompareCard from '../../common/compare-card'
import Link from '../../common/link'
import cx from 'classnames'
import styles from './compareThumbnails.module.scss'

const CompareThumbnails = ({ comparisons, wrapperClassName }) => {
  if (isEmpty(comparisons)) {
    return null
  }

  return (
    <section className={wrapperClassName}>
      <div className={cx(styles['drive-home-compare-thumbnails'])}>
        <div className={cx(styles['drive-home-compare-thumbnails__header-section'])}>
          <h2 className={cx(styles['drive-home-compare-thumbnails__header-section__title'])}>
            Compare Price & Specs
          </h2>
          <Link href='/compare-specs/'>
            <a className={cx(styles['drive-home-compare-thumbnails__header-section__link'])}>
              Compare Specific Cars
            </a>
          </Link>
        </div>
        <div className={cx(styles['drive-home-compare-thumbnails__comparisons'])}>
          {comparisons.map((comparison) => (
            <div
              key={comparison.id}
              className={cx(styles['drive-home-compare-thumbnails__compare-card-wrapper'])}
            >
              <CompareCard
                {...comparison}
                imageAttributes={{
                  loading: 'lazy',
                  width: 400,
                  height: 225,
                  fetchpriority: 'low'
                }}
                linkTag='h3'
                className={cx(styles['drive-home-compare-thumbnails__compare-card'])}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

CompareThumbnails.propTypes = {
  comparisons: PropTypes.array.isRequired,
  wrapperClassName: PropTypes.string
}

export default CompareThumbnails
