import cx from 'classnames'
import React from 'react'
import useIsTabletScreen from '../../../utils/hooks/useIsTabletScreen'
import Ad from '../../common/ad-units/ad'
import AdSpacing from '../../common/ads/AdSpacing'
import styles from './conditional-roofrack.module.scss'
import adSpacingStyles from './../../common/ads/adSpacing.module.scss'

// Conditionally renders roofrack Ad in homepage template
const ConditionalRoofRack = () => {
  const { isTablet } = useIsTabletScreen()

  if (isTablet) {
    return null
  }
  return (
    <AdSpacing type='roofrack' className={adSpacingStyles['drive-ad-spacing__roofrack']}>
      <div className={cx(styles['d-roofrack'], 'gam-ad-wrapper')}>
        <Ad id={'gam_roofrack_pos1'} onLoadHandle={undefined} />
      </div>
    </AdSpacing>
  )
}

export default ConditionalRoofRack
