import isEmpty from 'lodash/isEmpty'
import { useQuery } from '@apollo/client'
import { FunctionComponent, memo, useEffect, useState } from 'react'
import {
  GET_HOME_SLIDE_CAR_ADVICE,
  GET_HOME_SLIDE_NEWS,
  GET_HOME_SLIDE_REVIEW
} from '../../../../queries/home/get-slide'
import CarouselCard from '../carouselCard'
import AdLoadingCard from './../adLoadingCard'
import AdTagless from '../../../../components/common/ad-units/adTagless'
import { useNamedPageContext } from '../../../../utils/contexts/pageContext'
import {
  AdPosProps,
  AdUnitImageType,
  AdUnitWPType,
  CarouselAdCardProps,
  GetAdUnitImageType
} from './types'
import { MountedGlide } from '@grille/components/common/glideSlider'

/**
 * Carousel Ad Component - fetches ad and calls a callback whenever thats done, parent decides to remove it if ad isnt served
 *
 * @param {Object} props.data comes from parent - doesnt include article data
 * @param {Function} props.sliderCallBack is called once ad has been processed
 *
 */
const CarouselAdCard: FunctionComponent<CarouselAdCardProps> = ({
  data: cardData,
  contextName
}) => {
  const { store } = useNamedPageContext(contextName) as Record<string, any>
  const { glide }: { glide: MountedGlide } = store || {}
  const { isArticleIncludesVideo, showSticky, item, thisSlideIndex } = cardData
  const [article, setArticle] = useState<null | {
    article: AdUnitImageType | AdUnitWPType
    fetched: boolean
  }>(null)

  // skip these calls on first render as we don't know the variables yet
  const { refetch: refetchNews } = useQuery(GET_HOME_SLIDE_NEWS, { variables: {}, skip: true })
  const { refetch: refetchReviews } = useQuery(GET_HOME_SLIDE_REVIEW, { variables: {}, skip: true })
  const { refetch: refetchCarAdvice } = useQuery(GET_HOME_SLIDE_CAR_ADVICE, {
    variables: {},
    skip: true
  })

  // Load the carousel ad units from GAM & pull post data as requried.
  useEffect(() => {
    // Only run ad calls client side.
    if (!process.browser) return

    const getCarouselAdUnitImageType: GetAdUnitImageType = (rawAdData = {}) => {
      if (!Object.keys(rawAdData).length) return rawAdData // bail early if nothing in rawAdData
      const title = rawAdData.caption
      return {
        id: rawAdData.requestId,
        title: title,
        image: {
          isExternal: true,
          title: title,
          altText: title,
          sourceUrl: rawAdData.images?.desktop
        },
        mobileImage: rawAdData.images?.mobile,
        url: rawAdData.clickthroughUrl,
        isExternalURL: true,
        impressionTrackingUrls: rawAdData.impressionTrackingUrls
      }
    }

    const getCarouselAdUnitWPType = async (
      rawAdData: { [key: string]: any } = {}
    ): Promise<AdUnitWPType | null> => {
      if (Object.keys(rawAdData).length === 0 || !('postType' in rawAdData)) return rawAdData // bail early if nothing in rawAdData or no postType is found
      return new Promise((resolve, reject) => {
        let refetchType
        const postType = rawAdData?.postType?.toLowerCase() ?? ''
        switch (postType) {
          case 'news':
            refetchType = refetchNews
            break
          case 'review':
            refetchType = refetchReviews
            break
          case 'caradvice':
            refetchType = refetchCarAdvice
            break
          default:
            break
        }
        if (refetchType) {
          refetchType({ uri: rawAdData?.postIdentifier ?? '' }).then((response) => {
            const { data, error, errors } = response
            if (error || errors) {
              reject(error)
            } else {
              const resData = data?.[postType]
              const featuredImage = data?.[postType]?.featuredImage?.node
              if (resData && featuredImage) {
                resolve({
                  id: rawAdData.requestId,
                  title: resData.title,
                  image: featuredImage,
                  url: rawAdData.clickthroughUrl ?? resData.uri,
                  isExternalURL: !!rawAdData.clickthroughUrl,
                  impressionTrackingUrls: rawAdData.impressionTrackingUrls
                })
              } else {
                resolve(null)
              }
            }
          })
        } else {
          resolve(null)
        }
      })
    }

    const getCarouselAdUnit = async (adData: AdPosProps) => {
      if (!adData.isLoaded) {
        let slideData: null | Record<string, any> = null
        try {
          const gamIndex = adData.index
          const { data } = await AdTagless({ id: adData.slot, endpoint: null })
          if (data?.postType === 'image') {
            const carouselAdUnitImageType = getCarouselAdUnitImageType(data)
            if (!isEmpty(carouselAdUnitImageType)) {
              if (gamIndex !== -1) {
                slideData = carouselAdUnitImageType
              }
            }
          } else {
            const carouselAdUnitWpType = (await getCarouselAdUnitWPType(data)) as Record<
              string,
              any
            >
            // only valid generated ads gets rendered here
            if (!isEmpty(carouselAdUnitWpType)) {
              if (gamIndex !== -1) {
                slideData = carouselAdUnitWpType
              }
            }
          }

          if (!isEmpty(slideData)) {
            setArticle({ article: slideData, fetched: true })
          } else {
            // remove it from the slider if no data
            setArticle({ article: slideData, fetched: true })
          }
        } catch (error) {
          //eslint-disable-next-line
          console.error(`getCarouselAdUnit - Error - ${error}`)
        }
      }
    }
    getCarouselAdUnit({ slot: item.slotName, ...item })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (article?.fetched) {
      glide.update({})
    }
  }, [article?.fetched, glide])

  if (!article?.article) {
    if (article?.fetched) {
      return null
    } else {
      return <AdLoadingCard />
    }
  }

  const adArticle = article.article

  return (
    <div className='w-full'>
      <CarouselCard
        data={{
          image: {
            ...adArticle?.image,
            fetchpriority: thisSlideIndex === 1 ? 'high' : 'low',
            loading: thisSlideIndex === 1 ? 'eager' : 'lazy'
          },
          title: adArticle?.title,
          url: adArticle?.url,
          isArticleIncludesVideo,
          showSticky,
          isExternalURL: adArticle?.isExternalURL
        }}
      />
      {!isEmpty(adArticle?.impressionTrackingUrls) &&
        adArticle?.impressionTrackingUrls?.map((trackingUrl, index) => {
          return (
            <img
              src={trackingUrl}
              width='1'
              height='1'
              className='gam-impression-tracker'
              key={index}
              data-component='carousel'
            />
          )
        })}
    </div>
  )
}

export default memo(CarouselAdCard)
