'use client'
import { FunctionComponent } from 'react'
import Layout from '@grille/components/Layout'
import HomePageTemplate from '@grille/components/templates/home'

export const Home: FunctionComponent<{ data: Record<string, any> }> = ({ data }) => {
  const dataWithAds = {
    ...data,
    extraAdId: 'gam_oop_pos0',
    extraAdClassName: 'left-0'
  }
  return (
    <Layout
      data={dataWithAds}
      homepage
      adId='gam_roofrack_hs_pos1'
      customContainerClasses='container mx-auto relative'
      roofrackWrapperClassname='homepage-roofrack'
      isAppRoute
    >
      <HomePageTemplate data={dataWithAds} />
    </Layout>
  )
}
