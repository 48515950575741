import isEmpty from 'lodash/isEmpty'
import { FunctionComponent, useEffect, useState } from 'react'
import Image from '../../common/image'
import styles from './curated-car-list.module.scss'
import { ImageType } from '../../../types/media'
import Selections from './Selections'
import Models from './Models'

/**
 * Curated Car List coming from WP -> initially used for homepage
 * @param carListBanner sections of car lists coming from WP
 * @returns FunctionComponent
 */
const CuratedCarList: FunctionComponent<Props> = ({ carListBanner }) => {
  const [indexToShow, setIndexToShow] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  const models = carListBanner?.map((banner) => {
    return banner?.carList?.models?.nodes?.slice(0, 8) ?? {}
  })

  const titleOptions: { label: string; option: string }[] = []

  const bannerTitles = carListBanner?.map((banner, _i) => {
    const title = banner?.carList?.title ?? ''
    titleOptions.push({
      label: title,
      option: title
    })
    return title
  })

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      setIsMobile(true)
    }
  }, [])

  if (isEmpty(carListBanner)) {
    return null
  }

  return (
    <div className={styles['d-curated-carlist__wrapper']}>
      <div className={styles['d-curated-carlist__tabs-wrapper']}>
        <Selections
          isMobile={isMobile}
          bannerTitles={bannerTitles}
          setIndexToShow={setIndexToShow}
          indexToShow={indexToShow}
          titleOptions={titleOptions}
        />
      </div>
      <Models models={models} indexToShow={indexToShow} bannerTitles={bannerTitles} />
    </div>
  )
}

type Props = {
  carListBanner: {
    carList: {
      title: string
      models: {
        nodes: Image[]
      }
    }
  }[]
}

export type Image =
  | ImageType & {
      featuredImage: any
      uri: string
    }

export default CuratedCarList
