import cx from 'classnames'
import { ElementType, FunctionComponent, useState, useEffect } from 'react'
import Tooltip from './Tooltip'
import { MEDIUM_WINDOW_WIDTH } from '@grille/constants'
import { Color } from '../../../../constants/colors'
import { isTestEnv } from '../../../../utils/functions/isTestEnv'
import styles from './awardIcon.module.scss'
import Link, { linkDefaultProps } from '../../link'

const isTest = isTestEnv()
/**
 * Award component that renders the icon and tooltip
 * @returns FC
 */

const Award: FunctionComponent<Props> = ({
  slug,
  toggleTooltip,
  activeTooltip,
  id,
  tooltipProps,
  iconComp: Icon,
  uri = ''
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.innerWidth <= MEDIUM_WINDOW_WIDTH) {
      setIsMobile(true)
    }
  }, []) // only once

  return (
    <>
      {isMobile ? (
        <span
          className={cx(styles['drive-award-icons__dcoty-winner'], {
            overall: tooltipProps?.name?.includes('Overall')
          })}
          data-testid={isTest ? slug : undefined}
          onClick={() => toggleTooltip(id)}
        >
          <Icon height='20' color={Color.white} />
          {activeTooltip === id && <Tooltip {...tooltipProps} uri={uri} />}
        </span>
      ) : (
        <Link {...linkDefaultProps} href={uri}>
          <a
            className={cx(styles['drive-award-icons__dcoty-winner'], {
              overall: tooltipProps?.name?.includes('Overall')
            })}
            data-testid={isTest ? slug : undefined}
            onMouseOver={() => toggleTooltip(id)}
          >
            <Icon height='20' color={Color.white} />
            <Tooltip {...tooltipProps} />
          </a>
        </Link>
      )}
    </>
  )
}

type Props = {
  slug: string
  toggleTooltip: (id: string) => void
  activeTooltip: string | null
  id: string
  tooltipProps: {
    name: string | null
    categoryName: string
    year: string
  }
  uri: string
  iconComp: ElementType
}

export default Award
