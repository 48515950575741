import ArticleCard from './article-card'
import styles from './whatsOnAtDrive.module.scss'

const WhatsOnAtDrive = ({ blocks = [], title }: WhatsOnAtDriveProps) => {
  const mainBlock = blocks?.[0] ?? {}
  const remainingBlocks = blocks?.slice(1) ?? []

  return (
    <div className={styles['drive-whats-on-at__wrapper']}>
      <h2 className={styles['drive-whats-on-at__heading']}>{title}</h2>
      <div className={styles['drive-whats-on-at__blocks-layout']}>
        <ArticleCard block={mainBlock} compact={false} />
        <div className={styles['drive-whats-on-at__blocks-container']}>
          {remainingBlocks.map((block, index) => {
            return <ArticleCard block={block} key={index} compact={true} />
          })}
        </div>
      </div>
    </div>
  )
}

type WhatsOnAtDriveProps = {
  title: string
  blocks: { [key: string]: any }[]
}

export default WhatsOnAtDrive
