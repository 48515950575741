import cx from 'classnames'
import styles from './home.module.scss'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Ad from '../../common/ad-units/ad'
import AutoRefresh from '../../common/autoRefresh'
import CuratedCarList from '../../home/curate-car-list/CuratedCarList'
import PageWrapper from '../../common/pageWrapper/PageWrapper'
import { CARS_FOR_SALE_CARDS } from '../../../constants'
import Carousel from '../../home/carousel'
import ConditionalRoofrack from '../../home/conditional-roofrack'
import { NamedPageProvider } from '../../../utils/contexts/pageContext'
import FindACar from '../../../components/find-a-car'
import AdSticky from '../../common/ads/AdSticky'
import SponsoredContent from '../../../components/post/blocks/sponsored-content'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'

/** Following component can be lazyLoaded later
 * temporary revert for Home index.js file of https://github.com/caradvice/drive-grille/pull/4244/
 * see full details on change made in the pr at https://github.com/caradvice/drive-grille/blob/e1023e7de59cce434ecbfce99a88e6276a1849f2/components/templates/home/index.js
 */
import ArticleAnnexCards from '../../common/article-annex-cards'
import HomeArticleSlider from './articles-slider'
import WhatsOnAtDrive from '../../home/whats-on-at-drive'
import DealsModels from '../../home/deals'
import ComparisonCta from '../../showrooms/comparison-cta'
import CompareThumbnails from '../../home/compare-thumbnails'
import CarsForSale from '../../home/cars-for-sale'
import CarForSaleCard from '../../home/shortcut-card/car-for-sale'

const Home = ({ data }) => {
  const refreshTimeInMin = getRuntimeEnv('NEXT_PUBLIC_HOMEPAGE_AUTOREFRESH_MINS') || 4 // fallback to 4mins if no env variable
  const { newAndDemoCars, usedCars, availableMakes: makes } = data || {}
  const featuredPostSlider = data?.page?.featuredPostSlider ?? []
  const featuredPostLists = data?.page?.featuredPostLists ?? {}
  const metaTags = {
    rssFeed: data?.page?.rssFeed ?? [],
    dataLayer: data?.page?.dataLayer ?? [],
    targeting: data?.page?.targeting ?? {}
  }
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768

  const featuredCarouselItems = featuredPostSlider?.length
    ? featuredPostSlider
        .filter((item) => {
          return item?.image ?? null
        })
        .map((item) => {
          if (item?.databaseId) {
            const title = item?.title ?? ''
            const stickyPost = item?.post?.stickyPosts ?? {}
            const featuredImg = item?.image ?? {}
            return {
              id: `${item?.databaseId}` ?? '',
              title: title,
              image: featuredImg ?? {},
              url: item?.url ?? '',
              sticky: stickyPost?.stickyType ?? '',
              tags: item?.post?.tags ?? {}
            }
          }
          return null
        })
    : []

  if (isEmpty(data)) {
    return null
  }

  return (
    <PageWrapper
      hideBreadcrumb
      seoData={{ seo: data?.page?.seo }}
      metaTags={metaTags}
      className='homepage'
      isAppRoute
    >
      <AutoRefresh refreshTimeInMin={refreshTimeInMin}>
        <h1 className='sr-only'>
          Drive - Australia's Home of Car News, Expert Reviews, Ratings & Specs
        </h1>
        <div className='home-page'>
          <div className={cx(styles['drive-home__wrapper'])}>
            <NamedPageProvider contextName='homeGlide'>
              <Carousel items={featuredCarouselItems} contextName='homeGlide' />
            </NamedPageProvider>
            <FindACar inputIdPrefix='fac-home' isMobile={isMobile} isSSR={false} />
            <CuratedCarList carListBanner={data?.page?.homepageMeta?.carListBanner ?? []} />
            <ConditionalRoofrack />

            <ArticleAnnexCards
              layoutType='col-3'
              headingClass={cx(styles['drive-home__section-heading'])}
              headingElement='h3'
              linkUrl='/news/'
              topLinkLabel='Latest News'
              bottomLinkLabel='Read more News'
              isExternalLink={false}
              articles={data?.latestNews?.edges ?? []}
              limit={9}
              showDate
              onHomePage
              thumbnailClassName='homepage-article-thumbnail'
              carListBanner={data?.page?.homepageMeta?.carListBanner ?? []}
              imageAttributes={{
                loading: 'lazy',
                imageLoading: 'lazy',
                fetchpriority: 'low',
                width: 140,
                height: 96
              }}
            />

            <HomeArticleSlider
              articles={data?.latestReviews?.edges ?? []}
              customClass={cx(styles['drive-home__latest-review-slider'])}
              headingClass={cx(styles['drive-home__section-heading'])}
              linkUrl='/reviews/'
              topLinkLabel='Latest Reviews'
              bottomLinkLabel='Read more Reviews'
              imageAttributes={{
                loading: 'lazy',
                width: 296,
                height: 167,
                fetchpriority: 'low'
              }}
            />

            <div className={cx(styles['drive-home__three-col-layout'])}>
              <WhatsOnAtDrive
                blocks={featuredPostLists?.blocks ?? []}
                title={featuredPostLists?.title ?? ''}
              />
              <div className={cx(styles['drive-home__advice-articles__wrapper'])}>
                <ArticleAnnexCards
                  className='advice-articles'
                  headingClass={cx(styles['drive-home__section-heading-ca'])}
                  headingElement='h3'
                  linkUrl='/caradvice/'
                  topLinkLabel='Trending CarAdvice'
                  bottomLinkLabel='Read more CarAdvice'
                  isExternalLink={false}
                  articles={data?.latestCarAdvice?.edges}
                  limit={4}
                  showDate
                  onHomePage
                  thumbnailClassName='homepage-article-thumbnail'
                  imageAttributes={{
                    loading: 'lazy',
                    imageLoading: 'lazy',
                    fetchpriority: 'low',
                    width: 176,
                    height: 99
                  }}
                  sizeName={'HOMEPAGE_CAR_ADVICE_CARD_THUMBNAIL'}
                />
              </div>
              <div className={cx(styles['drive-home__ad-posR1'])}>
                <Ad id='gam_annex_posR1' />
              </div>
            </div>

            <DealsModels data={data?.models} />

            <AdSticky
              wrapperClassName={cx(styles['drive-home__ad-pos2-wrapper'])}
              adId='gam_inreads_pos2'
              adClassName={cx(styles['drive-home__ad-pos2'])}
            />

            <HomeArticleSlider
              customClass={cx(styles['drive-home__latest-owner-reviews-slider'])}
              articles={data?.ownerReviews?.edges ?? []}
              headingClass={cx(styles['drive-home__section-heading'])}
              linkUrl='/reviews/'
              topLinkLabel='Latest Owner Reviews'
              bottomLinkLabel='Read more Reviews'
              imageAttributes={{
                loading: 'lazy',
                width: 296,
                height: 167,
                fetchpriority: 'low'
              }}
            />

            <HomeArticleSlider
              customClass={cx(styles['drive-home__latest-caradvice-slider'])}
              articles={data?.latestCarAdvice?.edges ?? []}
              headingClass={cx(styles['drive-home__section-heading'])}
              linkUrl='/caradvice/'
              topLinkLabel='Latest CarAdvice'
              bottomLinkLabel='Read more CarAdvice'
              imageAttributes={{
                loading: 'lazy',
                width: 296,
                height: 167,
                fetchpriority: 'low'
              }}
            />

            <AdSticky
              wrapperClassName={cx(styles['drive-home__ad-pos3-wrapper'])}
              adClassName={cx(styles['drive-home__ad-pos3'])}
              adId='gam_inreads_pos3'
              pageStoreStatus='isInreadsPos3Sticky'
              isStickyProps
            />

            <ComparisonCta
              data={data?.page?.showroomsComparisonCta ?? {}}
              wrapperClassname='homepage'
            />

            <SponsoredContent
              overwriteSpacing
              customStyle={{ paddingTop: '66px', paddingBottom: '28px', marginRight: 'auto' }}
              customStyleTab={{ paddingTop: '66px', paddingBottom: '28px', marginRight: 'auto' }}
              customStyleMobile={{ paddingTop: '34px', paddingBottom: '0px', marginRight: 'auto' }}
            />

            {!isEmpty(data?.comparisons?.nodes) && (
              <CompareThumbnails
                comparisons={data?.comparisons?.nodes}
                wrapperClassName={cx(styles['drive-home__compare-cards-wrapper'])}
              />
            )}

            <AdSticky
              wrapperClassName={cx(styles['drive-home__ad-pos4-wrapper'])}
              adClassName={cx(styles['drive-home__ad-pos4'])}
              adId='gam_inreads_pos4'
            />

            <div className={cx('car-for-sales', styles['drive-home__cfs'])}>
              <h2 className={cx(styles['drive-home__cfs__title'])}>Cars For Sale</h2>
              <div className={cx(styles['drive-home__cfs__container'])}>
                <div className={cx(styles['drive-home__cfs__section'])}>
                  {!isEmpty(newAndDemoCars) ? (
                    <CarsForSale
                      titleUrl='/cars-for-sale/search/new/#listingType=["demo"]'
                      title='New + Ex-Demo cars'
                      carsToShow={CARS_FOR_SALE_CARDS}
                      cars={newAndDemoCars}
                      listingType='new'
                      bottomLinkText='Shop all New + Demo Cars'
                      bottomLinkUrl='/cars-for-sale/search/new/#listingType=["demo"]'
                    />
                  ) : null}
                  {!isEmpty(usedCars) ? (
                    <CarsForSale
                      titleUrl='/cars-for-sale/search/used/'
                      title='Used Cars'
                      className={cx(styles['drive-home__cfs__section-used'])}
                      carsToShow={CARS_FOR_SALE_CARDS}
                      cars={usedCars}
                      listingType='used'
                      bottomLinkText='Shop Used Cars'
                      bottomLinkUrl='/cars-for-sale/search/used/'
                    />
                  ) : null}
                </div>

                <CarForSaleCard
                  className={cx(styles['drive-home__cfs__card'])}
                  makes={makes}
                  data={data?.page?.homepageMeta?.carsCard}
                />
              </div>
            </div>
            <div className={cx(styles['drive-home__ad-pos5-wrapper'])}>
              <Ad id='gam_inreads_pos5' className={cx(styles['drive-home__ad-pos5'])} />
            </div>
          </div>
        </div>
      </AutoRefresh>
    </PageWrapper>
  )
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
  hideRoofrackAd: PropTypes.func,
  shouldHideRoofrack: PropTypes.bool
}

export default Home
