import cx from 'classnames'
import Link from '../link'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import Image from '../../common/image'
import AwardIcons from './award-icons'
import ThumbnailImage from '../../common/thumbnail-image'
import { hasValidDeal } from '../../../utils/functions/showrooms'
import { DCOTY_TOP_FIVE_TERM_SLUG } from '../../../constants/slug'
import { getSingularOrPluralText } from '../../../utils/functions/miscellaneous'
import React from 'react'
import CarPriceRange from './price-range'
import styles from './carThumbnail.module.scss'
/**
 * CarThumbnail Component.
 *
 * @TODO data structure can change later, when we fetch the real data.
 *
 * @param {Array, Boolean, String} props {car, featured, className} data.
 *
 * @returns {JSX.Element}
 */

const CarThumbnail = ({
  title = '',
  modelUri = '',
  stickyType,
  variantCount = 0,
  priceRange = '',
  makeLogoUrl = '',
  featured,
  featuredImage = {},
  className = '',
  deals = [],
  isActiveDeal = false,
  awardTags = [],
  children,
  header,
  sizeName,
  makeLogo,
  makeLogoSizeName,
  isDealsPage = false,
  isExternal = false,
  validDealsCount = 0,
  isJumpLinkOrHashFragment = false,
  imageBg,
  includeInnerLink = true,
  imageAttributes = {}
}) => {
  const hasADeal = isActiveDeal || hasValidDeal(deals) || deals?.length > 0

  let childModelAvailable = ''
  if (
    header === 'Featured Showroom' &&
    children?.nodes &&
    isArray(children?.nodes) &&
    children.nodes.length > 0
  ) {
    childModelAvailable = children.nodes.length + ' models available'
  }

  /** Used to show gray bg color for the card if it has only top-5 tag */

  let isOnlyTopFiveModal = false
  const awardTagsSlug = []

  if (!isEmpty(awardTags) && Array.isArray(awardTags)) {
    awardTags.forEach(({ slug }) => {
      awardTagsSlug.push(slug)
    })

    isOnlyTopFiveModal =
      awardTagsSlug.includes(DCOTY_TOP_FIVE_TERM_SLUG) && (awardTags || []).length === 1
  }

  const renderVariant = () => {
    return (
      <span className={cx(styles['drive-car-thumbnail__variant-count'])}>
        {`${variantCount} ${getSingularOrPluralText(variantCount, 'variant')} available`}
      </span>
    )
  }

  const FeaturedImageHtml = () => {
    return (
      <>
        {featuredImage ? (
          <figure className={cx(styles['drive-car-thumbnail__featured-img'])}>
            <span className={cx(styles['drive-car-thumbnail__featured-img__sticky'])}>
              {!isEmpty(stickyType) && stickyType !== 'none' ? stickyType : ''}
            </span>
            <ThumbnailImage
              sizeName={sizeName}
              image={{ ...featuredImage, imageBg: imageBg, ...imageAttributes }}
              className={cx(styles['drive-car-thumbnail__featured-img-wrapper'])}
            />
          </figure>
        ) : null}

        {featured && (
          <span className={cx(styles['drive-car-thumbnail__featured-img__tag'], 'top-1')}>
            Featured
          </span>
        )}
      </>
    )
  }

  return (
    <div className={cx(styles['drive-car-thumbnail__container'], className)}>
      <div
        className={cx('relative', {
          'bg-gray-300': isOnlyTopFiveModal
        })}
      >
        {!children && (
          <span className={cx(styles['drive-car-thumbnail__make-img'])}>
            <Image
              sizeName={makeLogoSizeName}
              sourceUrl={makeLogo?.sourceUrl ?? makeLogoUrl}
              cloudinary={makeLogo?.cloudinary ?? {}}
              altText={makeLogo?.altText ?? ''}
            />
          </span>
        )}
        <AwardIcons awardTagsSlug={awardTagsSlug} />
        {modelUri ? (
          <>
            {includeInnerLink ? (
              <Link
                href={modelUri}
                isJumpLinkOrHashFragment={isJumpLinkOrHashFragment}
                isExternal={isExternal}
              >
                <a data-cy='CarThumbnail-permalink'>
                  <FeaturedImageHtml />
                </a>
              </Link>
            ) : (
              <FeaturedImageHtml />
            )}
          </>
        ) : null}
      </div>

      <div className={cx('deals-content', styles['drive-car-thumbnail__deals'])}>
        <Link
          href={modelUri}
          isExternal={isExternal}
          isJumpLinkOrHashFragment={isJumpLinkOrHashFragment}
        >
          {includeInnerLink ? (
            <a data-cy='CarThumbnail-permalink'>
              <h3 className={cx(styles['drive-car-thumbnail__deals__title'], 'truncate-2')}>
                {title}
              </h3>
            </a>
          ) : (
            <h3
              data-cy='CarThumbnail-permalink'
              className={cx(styles['drive-car-thumbnail__deals__title'], 'truncate-2')}
            >
              {title}
            </h3>
          )}
        </Link>

        <div className='text-sm text-gray-400 font-primary'>
          {!isEmpty(childModelAvailable) ? <div>{childModelAvailable}</div> : null}
          {variantCount && variantCount > 0 ? renderVariant() : null}
        </div>

        <CarPriceRange
          modelUri={modelUri}
          isExternal={isExternal}
          deals={deals}
          isDealsPage={isDealsPage}
          priceRange={priceRange}
          hasDeal={hasADeal}
          validDealsCount={validDealsCount}
        />
      </div>
    </div>
  )
}

CarThumbnail.propTypes = {
  title: PropTypes.string,
  featured: PropTypes.bool,
  modelUri: PropTypes.string,
  className: PropTypes.string,
  priceRange: PropTypes.string,
  variantCount: PropTypes.number,
  featuredImage: PropTypes.object,
  deals: PropTypes.array,
  awardTags: PropTypes.array,
  isActiveDeal: PropTypes.bool,
  sizeName: PropTypes.string,
  makeLogo: PropTypes.object,
  makeLogoUrl: PropTypes.string,
  makeLogoSizeName: PropTypes.string,
  isDealsPage: PropTypes.bool,
  validDealsCount: PropTypes.number
}

export default CarThumbnail
