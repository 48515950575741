import { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import logger from '@grille/utils/logger'
// import { LARGE_WINDOW_WIDTH } from './../../../constants'

const AutoRefresh = ({ children, refreshTimeInMin, beforeReloadCallback = undefined }) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    let currentScroll = 0
    let refreshTimeStore = 240
    try {
      refreshTimeStore = localStorage.getItem('refresh_home_in_sec')
    } catch (error) {
      logger.error(`Error - getting localstorage of refresh_home_in_sec`, JSON.stringify({ error }))
    }

    const defaultRefreshTime = refreshTimeInMin * 60 * 1000
    let refreshTime = isEmpty(refreshTimeStore)
      ? defaultRefreshTime
      : parseInt(refreshTimeStore) * 1000 // ms
    refreshTime = isNaN(refreshTime) ? defaultRefreshTime : refreshTime

    const throttle = (fn, wait) => {
      var time = Date.now()
      return function () {
        if (time + wait - Date.now() < 0) {
          fn()
          time = Date.now()
        }
      }
    }

    const waitSixSeconds = () => {
      setInterval(() => {
        if (currentScroll === document.documentElement.scrollTop) {
          if (beforeReloadCallback) {
            beforeReloadCallback()
          }
          window.location.reload()
        } else {
          currentScroll = document.documentElement.scrollTop // save current scroll to be compare after another 6 sec
        }
      }, 6000)
    }

    const throttleEvent = throttle(() => {
      currentScroll = document.documentElement.scrollTop // save current scroll every 5 sec
    }, 5000)

    const waitBeforeRefreshing = setTimeout(() => {
      currentScroll = document.documentElement.scrollTop
      window.addEventListener('scroll', throttleEvent)
      waitSixSeconds()
    }, refreshTime) // default is 4 mins

    return () => {
      clearTimeout(waitBeforeRefreshing)
      clearInterval(waitSixSeconds)
      window.removeEventListener('scroll', throttleEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

export default AutoRefresh
