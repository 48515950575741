import cx from 'classnames'
import startCase from 'lodash/startCase'
import Image from '../../../common/image'
import Link, { linkDefaultProps } from '../../../common/link'
import { PlayIconRounded } from '../../../icons'
import { sanitize } from '../../../../utils/functions/miscellaneous'
import styles from './../carousel.module.scss'
import { memo } from 'react'
import { FunctionComponent, useRef, useState } from 'react'
import { hasEllipsis } from '../../../../utils/functions/hasEllipsis'

const CarouselCard: FunctionComponent<Props> = ({ data }) => {
  const {
    image,
    title,
    url,
    sticky,
    isExternalURL = false,
    isArticleIncludesVideo,
    showSticky,
    index
  } = data

  const headlineRef = useRef<HTMLSpanElement>(null)
  const sanitizedTitle: string = sanitize(title)
  const [showHoverTitle, setShowHoverTitle] = useState<boolean>(false)

  const onHover = (): void => {
    const show: boolean = hasEllipsis(headlineRef.current, true)
    if (show !== showHoverTitle) {
      setShowHoverTitle(show)
    }
  }

  let loading = image?.loading

  if (!loading) {
    loading = index === 0 ? 'eager' : 'lazy'
  }

  return (
    <Link {...linkDefaultProps} href={url} isExternal={isExternalURL}>
      <a className={styles['drive-home-carousel__card-wrapper']}>
        <figure className={cx(styles['drive-home-carousel__img-wrapper'])}>
          <Image
            {...image}
            loading={loading}
            width='640'
            height='360'
            sizeName='HOMEPAGE_CAROUSEL_V2'
            alt={sanitizedTitle}
            altText={sanitizedTitle}
          />
          {isArticleIncludesVideo && (
            <span className={cx(styles['drive-home-carousel__play-btn-wrapper'])}>
              <PlayIconRounded
                height='50'
                width='50'
                className={cx(styles['drive-home-carousel__play-icon'])}
              />
            </span>
          )}
          {sticky && sticky !== 'none' && showSticky && (
            <span className={cx(styles['drive-home-carousel__img-label'])}>
              {startCase(sticky)}
            </span>
          )}
        </figure>
        <div className={cx(styles['drive-home-carousel__link-wrapper'])}>
          <div className={styles['drive-home-carousel__text-line-wrapper']}>
            <span className={styles['drive-home-carousel__line-style']} />
            <h3
              onMouseOver={onHover}
              // @ts-ignore
              ref={headlineRef}
              className={cx(styles['drive-home-carousel__link'])}
              dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
            />
          </div>
        </div>
      </a>
    </Link>
  )
}

type Props = {
  data: {
    image: {
      [key: string]: any
    }
    title: string
    url: string
    sticky?: string
    isExternalURL?: boolean
    isArticleIncludesVideo: boolean
    showSticky: boolean
    index?: number
  }
}

export default memo(CarouselCard)
