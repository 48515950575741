import cx from 'classnames'
import { createElement } from 'react'
import PropTypes from 'prop-types'
import Link from '../link'
import ThumbnailImage from '../thumbnail-image'
import styles from './compareCards.module.scss'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
const isTest = isTestEnv()

const CompareCard = ({
  uri,
  title,
  featuredImage,
  white = false,
  className,
  linkTag = 'h4',
  imageAttributes = {}
}) => {
  const cardTitle = title?.replace(/\svs\s/gim, "<span style='color: #3B97FA'> vs </span>")
  const image = featuredImage?.node ?? {}
  return (
    // FIX: Global CSS Refactor
    <div
      className={cx(styles['drive-compare-cards'], className, {
        [cx(styles['drive-compare-cards__white'])]: white
      })}
    >
      <div className='relative'>
        <Link href={uri}>
          <a data-cy='review-permalink'>
            <figure className={cx(styles['drive-compare-cards__figure'])}>
              <ThumbnailImage
                postTitle={title}
                image={{ ...image, ...imageAttributes }}
                altText={image?.altText}
                className={cx(styles['drive-compare-cards__img-wrapper'])}
                sizeName='ARTICLE_CARD_NO_META_DARK'
              />
            </figure>
          </a>
        </Link>
        <span className={cx(styles['drive-compare-cards__category'])}>compare specs</span>
      </div>

      <div className={cx(styles['drive-compare-cards__title-wrapper'], 'truncate-3')}>
        <Link href={uri}>
          <a data-testid={isTest ? 'test-card-title' : undefined} data-cy='review-permalink'>
            {createElement(linkTag || 'h4', {
              className: cx('truncate-3', styles['drive-compare-cards__title-link']),
              dangerouslySetInnerHTML: { __html: cardTitle }
            })}
          </a>
        </Link>
      </div>
    </div>
  )
}

CompareCard.propTypes = {
  white: PropTypes.bool
}

export default CompareCard
