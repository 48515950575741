import cx from 'classnames'
import PropTypes from 'prop-types'
import DealsInfo from '../deals-info'
import DealsInfoWithToolTip from '../deals-info-with-tooltip'
import PriceRange from '../../price-range'
import styles from './priceRange.module.scss'
import customFormatPrice from './../../../../utils/functions/customFormatPrice/customFormatPrice'
import isString from 'lodash/isString'

const CarPriceRange = ({
  isDealsPage = false,
  priceRange = '',
  deals = [],
  hasDeal = false,
  modelUri,
  validDealsCount = 0
}) => {
  return (
    <div className={cx(styles['drive-price-range__container'])}>
      {priceRange && (
        <div
          className={cx('justify-between', { 'truncate-1': !isDealsPage }, { flex: isDealsPage })}
        >
          {priceRange?.min ? (
            <PriceRange
              className='price'
              min={priceRange.min}
              max={priceRange.max}
              customFormatPrice={customFormatPrice}
            />
          ) : (
            isString(priceRange) && (
              <span className={cx(styles['drive-price-range__range'])}>
                {priceRange}
                <span className={cx(styles['drive-price-range__range-type'])}>MRLP</span>
              </span>
            )
          )}

          {isDealsPage && (deals?.length > 0 || validDealsCount) ? (
            <DealsInfoWithToolTip
              deals={deals}
              modelUri={modelUri}
              validDealsCount={validDealsCount}
            />
          ) : null}
        </div>
      )}

      {hasDeal && !isDealsPage ? (
        <DealsInfo deals={deals} validDealsCount={validDealsCount} />
      ) : null}
    </div>
  )
}

CarPriceRange.propTypes = {
  isDealsPage: PropTypes.bool,
  hasDeal: PropTypes.bool,
  priceRange: PropTypes.string,
  deals: PropTypes.array,
  validDealsCount: PropTypes.number
}

export default CarPriceRange
