import cx from 'classnames'
import styles from './curated-car-list.module.scss'
import Select from 'react-select'
import customStyles from './customStyles'
import DropdownIndicatorArrow from './DropdownIndicatorArrow'
import { FunctionComponent } from 'react'
import { amendedComponents } from '@grille/components/common/react-select/amended-attribute-components'

const Selections: FunctionComponent<Props> = ({
  isMobile,
  bannerTitles,
  setIndexToShow,
  indexToShow,
  titleOptions
}) => {
  return (
    <>
      {!isMobile ? (
        <ul>
          {bannerTitles?.map((title, i) => {
            return (
              <li
                key={`carlist-banner-${i}`}
                onClick={() => setIndexToShow(i)}
                className={cx(styles['d-curated-carlist__tabs-wrapper--title'], {
                  active: i === indexToShow
                })}
              >
                {title ?? ''}
              </li>
            )
          })}
        </ul>
      ) : (
        <Select
          placeholder={titleOptions?.[0]?.label ?? ''}
          styles={customStyles}
          className='carlist-react-select'
          classNamePrefix='carlist-react-select'
          components={{
            Input: amendedComponents.Input,
            Option: amendedComponents.Option,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            DropdownIndicator: DropdownIndicatorArrow
          }}
          onChange={(option: any) => {
            let index = 0
            titleOptions?.forEach((opt, i) => {
              if (opt.option === option.option) {
                index = i
              }
            })
            setIndexToShow(index)
          }}
          options={titleOptions}
        />
      )}
    </>
  )
}

type Props = {
  isMobile: boolean
  bannerTitles: string[]
  setIndexToShow: (i: number) => void
  indexToShow: number
  titleOptions: {
    label: string
    option: string
  }[]
}

export default Selections
