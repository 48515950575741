import cx from 'classnames'
import styles from './ad-loading-card.module.scss'

/**
 * Carousel Loading Component.
 *
 * @param {String} title Loading title.
 *
 * @returns {JSX.Element}
 */
const AdLoadingCard = ({ title = 'Loading...' }: Props) => {
  return (
    <div
      className={cx(
        styles['drive-article-loading-card__wrapper'],
        'article-thumbnail animate-pulse'
      )}
    >
      <div className={cx(styles['drive-article-loading-card__img-wrapper'])}></div>
      <div className={cx(styles['drive-article-loading-card__link-wrapper'])}>{title}</div>
    </div>
  )
}

type Props = {
  title?: string
}

export default AdLoadingCard
