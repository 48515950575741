import { Properties } from '@glidejs/glide'
import cx from 'classnames'
import Link, { linkDefaultProps } from '../../common/link'
import { FunctionComponent } from 'react'
import { SHOWROOM_MODEL_POSTS_SLIDER_SETTINGS } from '../../../constants'
import { Color } from '../../../constants/colors'
import { getCurrentBreakpointOptions } from '../../../utils/functions/glideSlider'
import ArticleSliderContainer from '../../common/articles/article-slider-container/article-slider-container'
import styles from './articles-slider.module.scss'

const HomeArticleSlider: FunctionComponent<Props> = ({
  articles,
  linkUrl,
  headingClass,
  topLinkLabel,
  bottomLinkLabel,
  imageAttributes,
  customClass
}) => {
  return (
    <div className={cx(styles['article-slider__article-slider-container'], customClass)}>
      <div className={cx(styles['article-slider__article-slider-wrapper'])}>
        {linkUrl ? (
          <Link {...linkDefaultProps} href={linkUrl}>
            <a className={cx(styles['article-slider__header-link'])}>
              <h2 className={cx(headingClass)}>{topLinkLabel}</h2>
            </a>
          </Link>
        ) : null}
        <div className={cx(styles['article-slider__glide-slides'])}>
          <ArticleSliderContainer
            sliderSettings={SHOWROOM_MODEL_POSTS_SLIDER_SETTINGS}
            articles={articles}
            removeExcess={{ shouldRemove: false }}
            cardWrapperTag={'div'}
            cardWrapperClass={'showroom-model-post-card-wrapper'}
            articleCardProps={{
              showCircleRating: true,
              showArticleExcerpt: true,
              commentIconProps: { color: Color.black },
              shareCountIconProps: { color: Color.black },
              imageSizeName: 'SHOWROOM_ARTICLE_CARD',
              imageAttributes: imageAttributes
            }}
            processAds={false}
            glideMountHandle={(mountedGlide: Properties) => {
              const perView =
                getCurrentBreakpointOptions(SHOWROOM_MODEL_POSTS_SLIDER_SETTINGS?.breakpoints ?? {})
                  ?.perView ?? 4
              perView === 4 && mountedGlide.disable()
            }}
          />
        </div>
        {linkUrl ? (
          <Link {...linkDefaultProps} href={linkUrl}>
            <a className={styles['article-slider__bottom-link']}>{bottomLinkLabel}</a>
          </Link>
        ) : null}
      </div>
    </div>
  )
}

type Props = {
  articles: Record<string, any>[]
  linkUrl: string
  headingClass: string
  topLinkLabel: string
  bottomLinkLabel: string
  imageAttributes: Record<string, any>
  customClass: string
}

export default HomeArticleSlider
