import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { POPULAR_MAKES_ALL, POPULAR_MAKES_CURRENT } from '../../constants/car-value-calculator'
import sortBy from 'lodash/sortBy'
import toLower from 'lodash/toLower'
import find from 'lodash/find'
import map from 'lodash/map'
import max from 'lodash/max'
import merge from 'lodash/merge'
import min from 'lodash/min'
import { GET_TAILPIPE_MAKES } from '../../queries/get-makes'
import client from '../../apollo/client'
import { isCurrentRelease } from './variant'

/**
 * Get Sorted makes
 *
 * Separate Popular Makes from Makes
 * Then puts them first in the list.
 *
 * @param makes
 * @returns sortedMakes
 */
export const getAllSortedMakes = (makes, allPopularMakes = false) => {
  if (isEmpty(makes) || !isArray(makes)) {
    return []
  }

  /** Separate Popular Makes from Makes */
  const popularMakes = getPopularMakes(makes, allPopularMakes)

  return [...popularMakes, ...makes]
}

/**
 * Return filterd list of popular makes
 * @param {Array} makes List of Makes
 * @param {Boolean} allPopularMakes Filter from all popular makes
 * @returns {Array} Returns list of popular makes from given make list
 */
export const getPopularMakes = (makes, allPopularMakes = false) => {
  if (isEmpty(makes) || !isArray(makes)) {
    return []
  }
  const popularMakeList = allPopularMakes ? POPULAR_MAKES_ALL : POPULAR_MAKES_CURRENT
  return makes && makes && isArray(makes)
    ? makes?.filter((node) => popularMakeList.includes(node?.name?.toUpperCase()))
    : []
}

export const filterModels = (models = [], category) => {
  if (isEmpty(category)) {
    return models
  }
  return models?.filter((model) =>
    model?.categories?.map((c) => toLower(c)).includes(category.replace(/-/g, ' '))
  )
}

export const getCategoryFilterOptions = (models = []) => {
  return [
    {
      id: 'all',
      label: 'All',
      filter: (models) => filterModels(models),
      count: models?.length
    },
    {
      id: 'passenger',
      label: 'Passenger',
      filter: (models) => filterModels(models, 'passenger'),
      count: filterModels(models, 'passenger').length
    },

    {
      id: 'suv',
      label: 'SUV',
      filter: (models) => filterModels(models, 'suv'),
      count: filterModels(models, 'suv').length
    },
    {
      id: 'ute-vans',
      label: 'Utes & Vans',
      filter: (models) => filterModels(models, 'light commercial'),
      count: filterModels(models, 'light commercial').length
    },
    {
      id: 'electric-hybrid',
      label: 'Electric & Hybrid',
      filter: (models) => models.filter((model) => model.hasElectric),
      count: models.filter((model) => model.hasElectric).length
    }
  ]
}

export const sortModels = (models, sortByField, order) => {
  let field = sortByField
  // If price is sorted Ascending then sort by minimum price else sort by max price
  if (field === 'priceRange') {
    field = order === 'ASC' ? 'priceRange.min' : 'priceRange.max'
  }
  const sortedModels = sortBy(models, [field])
  return order === 'ASC' ? sortedModels : sortedModels.reverse()
}

export const getSortOptions = () => {
  return [
    { field: 'priceRange', order: 'ASC', label: 'Price (Low to High)' },
    { field: 'priceRange', order: 'DESC', label: 'Price (High to Low)' },
    { field: 'title', order: 'ASC', label: 'Alphabetical (A-Z)' },
    { field: 'title', order: 'DESC', label: 'Alphabetical (Z-A)' },
    { field: 'driveRating', order: 'DESC', label: 'Drive Rating' }
  ]
}

/**
 * Function to check if models have all mandatory details
 * @param model {Model}
 * @returns {boolean} Returns true if model data is valid
 */
const checkIsValidModel = (model) => {
  // TODO: Update function to check if fields exist on model data
  if (isEmpty(model)) {
    return false
  }

  if (isEmpty(model?.priceRange) || !('min' in model.priceRange) || !('max' in model.priceRange)) {
    return false
  }

  if (isEmpty(model?.title)) {
    return false
  }

  return true
}

/**
 * @param {Arrany} models
 * @returns Min warranty year from make models
 */
const getMakeWarranty = (models = []) => {
  const modelYearsList = models.map((m) => m.warrantyYears?.[0] ?? 0)
  const minWarrantyYear = min(modelYearsList)
  return minWarrantyYear ? `${minWarrantyYear}` : ''
}

const getMakePriceRange = (models = []) => {
  if (isEmpty(models)) {
    return { min: 0, max: 0 }
  }

  const minPrices = models.map((model) => {
    return isCurrentRelease(model)
      ? model.type === 'DRIVE'
        ? model?.priceRange?.min
        : model?.priceRangeCurrentRelease?.min
      : model?.priceRange?.min
  })

  const maxPrices = models.map((model) => {
    return isCurrentRelease(model)
      ? model.type === 'DRIVE'
        ? model?.priceRange?.max
        : model?.priceRangeCurrentRelease?.max
      : model?.priceRange?.max
  })

  return { min: min(minPrices), max: max(maxPrices) }
}

const getModels = (wpModels, tailpipeModels) => {
  const models = []
  const mergedModels = map(tailpipeModels, function (item) {
    return merge(
      item,
      find(
        wpModels,
        (wpmodel) => wpmodel.uuid === item?.uuid && !wpmodel?.makeModelSlug?.includes('__trashed') // make sure its not a trashed models
      )
    )
  })

  mergedModels.forEach((model) => {
    const fuelTypes =
      (model?.vehicles ?? []).map((v) => {
        return v?.fuelType?.slug
      }) ?? []
    const hasElectric = fuelTypes.some((fuelType) => ['electric', 'hybrid'].includes(fuelType))
    const category = model?.categories?.[0] ?? ''
    const driveRating = model?.latestRating?.nodes?.[0]?.rating?.overall ?? ''
    /* use currentRealese price range
     * when its currentRelease and not a custom Drive vehicle type
     */
    const defaultPriceRange = { min: 0, max: 0 }
    const priceRange = isCurrentRelease(model)
      ? model?.type === 'DRIVE'
        ? model?.priceRange ?? defaultPriceRange
        : model?.priceRangeCurrentRelease
      : model?.priceRange
    const updatedModel = { hasElectric, ...model, category, driveRating, priceRange }
    const isValidModel = checkIsValidModel(updatedModel)
    if (isValidModel) {
      models.push(updatedModel)
    }
  })

  return models
}

export const getMakePageData = (pageData, tailpipeData, modelsData) => {
  const wpData = pageData?.page ?? {}
  const wpModels = modelsData?.nodes ?? []
  const tailpipeModels = tailpipeData?.models ?? []
  const models = getModels(wpModels, tailpipeModels)

  return {
    wpTitle: wpData?.title,
    description: wpData?.blocks?.[0]?.attributes?.content ?? '',
    heroMedia: wpData?.heroMedia ?? {},
    tailpipeTitle: tailpipeData?.title,
    uuid: wpData?.uuid ?? tailpipeData?.uuid,
    slug: wpData?.slug ?? tailpipeData?.slug,
    models,
    tailpipeAttributes: {
      warrantyYears: getMakeWarranty(models),
      priceRange: getMakePriceRange(models),
      modelCount: models?.length
    },
    wpAttributes: {
      topSeller: wpData?.topSeller ?? '',
      warrantyYears: wpData?.warrantyYears,
      headQuarter: wpData?.headQuarter ?? ''
    }
  }
}

/**
 * Function to get all makes from tailpipe
 * @returns {Array} List of Makes
 */
export const getAllMakes = async (query = {}) => {
  let makes = []
  let hasMore = false
  const perPage = 100
  let page = 0
  try {
    do {
      const { data, error } = await client.query({
        query: GET_TAILPIPE_MAKES,
        context: { tailpipe: true },
        variables: {
          query,
          page,
          perPage
        }
      })

      const moreMakes = data?.makes?.results ?? []
      makes = [...makes, ...moreMakes]
      if (!error && data?.makes?.pageInfo?.hasNextPage) {
        hasMore = true
        page++
      } else {
        hasMore = false
      }
    } while (hasMore)
  } catch (error) {
    return makes
  }
  return makes
}
