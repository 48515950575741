import cx from 'classnames'
import { FunctionComponent, useContext, useEffect, useState } from 'react'

import Ad from '../../ad-units/ad'
import AdSpacing from '../../ads/AdSpacing'
import useSpecificAd from '../../../../utils/hooks/useSpecificAd'
import { PageContext } from '../../../../utils/contexts/pageContext'
import styles from './ad-trigger.module.scss'
import useWindowWidth from '../../../../utils/hooks/use-window-width'
import useHeaderMenuIsVisible from '@grille/utils/hooks/useHeaderMenuIsVisible'

/**
 * Ad Component that triggers certain pageStore info, sticky is also triggered here
 * @param props.wrapperClassName optional tailwind classnames used in the div wrapper
 * @param props.adId required name or id of the ad, i.e gam_inreads_home_pos5, etc
 * @param props.adClassName optional module css classname for the ad itself
 * @param props.isStickyProps optional default value for stickiness, if its a sticky component it should default to true
 * @param props.pageStoreStatus optional the name of the page store to be listened to
 * @returns FunctionComponent
 */
const AdSticky: FunctionComponent<Props> = ({
  wrapperClassName,
  wrapperStyles = {},
  type = 'articleAd',
  adId,
  adClassName,
  isStickyProps = false,
  pageStoreStatus,
  isStickyUnderHeader,
  checkIfAdHasLoaded = false,
  adSpacingStyle = {}
}) => {
  const { pageStore } = useContext(PageContext)
  const [adsRefChange] = useSpecificAd()
  const [adHasNoData, setAdHasNoData] = useState(false)
  const [isWindow, setIsWindow] = useState<boolean>(false)
  const { headerMenuIsVisible } = useHeaderMenuIsVisible()
  const { windowWidth } = useWindowWidth()
  const isMobileScreen = windowWidth < 768
  const stickyStatus: boolean | undefined = isMobileScreen
    ? pageStore?.[pageStoreStatus ?? '']
    : undefined

  const onAdLoad = ({ isLoaded, isEmpty }: { isLoaded: boolean; isEmpty: boolean }) => {
    if (checkIfAdHasLoaded) {
      isLoaded && setAdHasNoData(isEmpty)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsWindow(true)
    }
  }, [])

  return (
    <>
      {isMobileScreen && isWindow && (
        <hr
          className={cx(styles['d-ad__hr'], { [styles['d-ad__hr--no-data']]: adHasNoData })}
          style={{ height: isStickyProps ? '1.63rem' : '0' }}
        />
      )}
      {!adHasNoData ? (
        <div
          style={wrapperStyles}
          className={cx(
            wrapperClassName,
            'ad-sticky-wrapper',
            `ad-sticky-wrapper-${adId}`,
            styles['d-ad__ad-wrapper'],
            {
              [cx(styles['d-ad__sticky'])]:
                isStickyProps && (stickyStatus || stickyStatus === undefined), //undefined cos default should always be sticky
              [cx(styles['d-ad__sticky--under-header'])]:
                isStickyUnderHeader || headerMenuIsVisible,
              [cx(styles['d-ad__not-sticky'])]: isStickyProps && !stickyStatus
            }
          )}
          ref={(ref) => adsRefChange(ref as HTMLDivElement, adId)}
        >
          <AdSpacing
            type={type}
            isInreadSticky={isStickyProps}
            adHasNoData={adHasNoData}
            customStyle={adSpacingStyle}
          >
            <Ad id={adId} className={cx(styles[adClassName ?? ''])} onLoadHandle={onAdLoad} />
          </AdSpacing>
        </div>
      ) : null}
      {isMobileScreen && isWindow && (
        <hr
          className={cx(styles['d-ad__hr'], { [styles['d-ad__hr--no-data']]: adHasNoData })}
          style={{ height: isStickyProps ? '1.63rem' : '0' }}
        />
      )}
    </>
  )
}

export default AdSticky

type Props = {
  wrapperClassName?: string
  wrapperStyles?: {
    [key: string]: any
  }
  type?: 'articleAd' | 'roofrack' | 'defaultAd' | 'bestCarListSingleAd'
  adId: string
  adClassName?: string
  isStickyProps?: boolean
  pageStoreStatus?: string
  isStickyUnderHeader?: boolean
  checkIfAdHasLoaded?: boolean
  adSpacingStyle?: Record<string, any>
}
