import { useCallback, useEffect } from 'react'

import { MOBILE_DEVICE_SIZES } from '../../../constants'
import useWindowWidth from '../../../utils/hooks/use-window-width'
import { checkBrowserType } from '../../../utils/functions/checkBrowserType'
import { mobileInreadsIntersection } from '../../../utils/functions/mobileInreadsIntersection'

const useMobileAdsBehavior = ({ refsArray, refCount, isNewImplementation = false }) => {
  const { windowSize } = useWindowWidth()
  const isMobileDevice = MOBILE_DEVICE_SIZES.includes(windowSize)

  const intersectChange = useCallback(
    (adRefs) => {
      if (adRefs?.current?.length < 1 && !isNewImplementation) {
        return
      }
      let isIOPolyfillLoaded = false
      let browserInfo = { isBrowserIE: false, isBrowserSafari: false }

      if (process.browser && !isIOPolyfillLoaded && isMobileDevice) {
        const { isBrowserIE, isBrowserSafari } = checkBrowserType()

        if (isBrowserIE || isBrowserSafari) {
          require('intersection-observer')
          isIOPolyfillLoaded = true
        }

        browserInfo = { isBrowserIE, isBrowserSafari }
      }
      if (!isIOPolyfillLoaded && (browserInfo.isBrowserIE || browserInfo.isBrowserSafari)) {
        return
      }

      if (!isMobileDevice) {
        return
      }

      const options = {
        root: null, // root will be viewport
        rootMargin: '0px',
        threshold: 0
      }

      mobileInreadsIntersection(adRefs.current, options)
    },
    [isMobileDevice, isNewImplementation]
  )

  // setting Intersection Observer polyfill when in IE or Safari
  useEffect(() => {
    if (isNewImplementation) {
      return
    }

    let isIOPolyfillLoaded = false
    let browserInfo = { isBrowserIE: false, isBrowserSafari: false }

    if (process.browser && !isIOPolyfillLoaded && isMobileDevice) {
      const { isBrowserIE, isBrowserSafari } = checkBrowserType()

      if (isBrowserIE || isBrowserSafari) {
        require('intersection-observer')
        isIOPolyfillLoaded = true
      }

      browserInfo = { isBrowserIE, isBrowserSafari }
    }
    if (!isIOPolyfillLoaded && (browserInfo.isBrowserIE || browserInfo.isBrowserSafari)) {
      return
    }

    if (process.browser && window.innerWidth > 600) {
      return
    }

    const options = {
      root: null, // root will be viewport
      rootMargin: '0px',
      threshold: 0
    }

    if (refCount && refsArray && process.browser) {
      mobileInreadsIntersection(refsArray, options)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refCount])

  return [intersectChange]
}

export default useMobileAdsBehavior
