import isEmpty from 'lodash/isEmpty'
import styles from './whatsOnAtDrive.module.scss'
import Link, { linkDefaultProps } from '../../common/link'
import ThumbnailImage from '../../common/thumbnail-image'
import cx from 'classnames'

const ArticleCard = ({ block, compact }: ArticleCardProps) => {
  if (isEmpty(block)) {
    return null
  }
  const { title, url, image } = block ?? {}
  const imgObj = {
    ...image,
    height: compact ? 270 : 540,
    width: compact ? 480 : 960,
    fetchpriority: 'low'
  }
  return (
    <div
      className={cx(styles['drive-whats-on-at__block'], {
        [cx(styles['drive-whats-on-at__block-full'])]: !compact
      })}
    >
      <Link {...linkDefaultProps} href={url}>
        <a>
          <ThumbnailImage
            imageLoading={'lazy'}
            sizeName={
              compact ? 'HOMEPAGE_WHATS_ON_AT_DRIVE_SMALL' : 'HOMEPAGE_WHATS_ON_AT_DRIVE_LARGE'
            }
            image={imgObj}
            altText={title}
            className={styles['drive-whats-on-at__block-img']}
          />
          <div className={styles['drive-whats-on-at__block-heading__wrapper']}>
            <h3 className={styles['drive-whats-on-at__block-heading']}>{title}</h3>
          </div>
        </a>
      </Link>
    </div>
  )
}

type ArticleCardProps = {
  block: { [key: string]: any }
  compact?: boolean
}

export default ArticleCard
