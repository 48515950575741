import { gql } from '@apollo/client'

import DriveImageFragment from '../fragments/image'

export const GET_HOME_SLIDE_NEWS = gql`
  query GET_HOME_SLIDE_NEWS($uri: String) {
    news: newsBy(uri: $uri) {
      id
      title
      uri
      featuredImage {
        node {
          ...DriveImageFragment
        }
      }
    }
  }
  ${DriveImageFragment}
`

export const GET_HOME_SLIDE_REVIEW = gql`
  query GET_HOME_SLIDE_REVIEW($uri: String) {
    review: reviewBy(uri: $uri) {
      id
      title
      uri
      featuredImage {
        node {
          ...DriveImageFragment
        }
      }
    }
  }
  ${DriveImageFragment}
`

export const GET_HOME_SLIDE_CAR_ADVICE = gql`
  query GET_HOME_SLIDE_CAR_ADVICE($uri: String) {
    caradvice: carAdviceBy(uri: $uri) {
      id
      title
      uri
      featuredImage {
        node {
          ...DriveImageFragment
        }
      }
    }
  }
  ${DriveImageFragment}
`
