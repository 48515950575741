import { gql } from '@apollo/client'
import { CarsForSaleListingFragment } from '../fragments/cars-for-sale'

const GET_HOME_LISTINGS = gql`
  query GET_HOME_LISTINGS($newDemoCarsQuery: WhereOptionsDealerListing, $usedCarsQuery: WhereOptionsDealerListing, $limit: Int!, $sort: SortInput) {
    newAndDemoCars: DealerListings(
      sort: $sort
      paginate: { page: 0, pageSize: $limit }
      where: $newDemoCarsQuery
    ) {
      ${CarsForSaleListingFragment}
    }
    usedCars: DealerListings(
      sort: $sort
      paginate: { page: 0, pageSize: $limit }
      where: $usedCarsQuery
    ) {
      ${CarsForSaleListingFragment}
    }
  }
`
export const GET_HOME_LISTINGS_CLIENT = gql`
  query GET_HOME_LISTINGS_CLIENT($whereOptionsDealerListing: WhereOptionsDealerListing, $limit: Int!, $sort: SortInput) {
    dealerListings: DealerListings(
      sort: $sort
      paginate: { page: 0, pageSize: $limit }
      where: $whereOptionsDealerListing
    ) {
      ${CarsForSaleListingFragment}
    }
  }
`
export default GET_HOME_LISTINGS
