import GlideSlider from '../../common/glideSlider'
import Image from '../../common/image'
import styles from './curated-car-list.module.scss'
import Link, { linkDefaultProps } from '../../common/link'
import { ImageType } from '../../../types/media'
import { FunctionComponent } from 'react'
import { HOME_CURATED_CAR_LIST_GLIDE_SETTINGS } from '../../../constants'
import { Properties } from '@glidejs/glide'
import { getCurrentBreakpointOptions } from '../../../utils/functions/glideSlider'

const Models: FunctionComponent<Props> = ({ models, indexToShow, bannerTitles }) => {
  const settings = HOME_CURATED_CAR_LIST_GLIDE_SETTINGS

  return (
    <>
      <div className={styles['d-curated-carlist__glide-wrapper']}>
        {models?.map((arr, index) => {
          const imageProps = {
            loading: 'lazy',
            fetchpriority: 'low'
          }
          return (
            <div
              key={`carlist-mobile-${index}`}
              style={{
                opacity: index === indexToShow ? 100 : 0,
                position: 'absolute',
                width: '100%',
                margin: '0 auto',
                zIndex: index === indexToShow ? 1 : -1,
                display: 'block'
              }}
            >
              <h2 className='sr-only'>{bannerTitles[index]}</h2>
              <GlideSlider
                glideOptions={settings}
                afterMountCallBack={(mountedGlide: Properties) => {
                  const perView =
                    getCurrentBreakpointOptions(
                      HOME_CURATED_CAR_LIST_GLIDE_SETTINGS?.breakpoints ?? {}
                    )?.perView ?? 8
                  perView === 8 && mountedGlide.disable()
                }}
              >
                {arr && Array.isArray(arr)
                  ? arr?.map((model, i) => {
                      return (
                        <Link
                          {...linkDefaultProps}
                          key={`model-carlist-${i}`}
                          href={model?.uri ?? ''}
                        >
                          <a className={styles['d-curated-carlist__model-image']}>
                            <Image
                              {...(model?.featuredImage ?? {})}
                              imageBg='bg-transparent'
                              alt={model?.title ?? ''}
                              sizeName='HOMEPAGE_BEST_CARS'
                              altText={model?.title ?? ''}
                              width={144}
                              height={81}
                              {...imageProps}
                            />
                            <h3>{model?.title}</h3>
                          </a>
                        </Link>
                      )
                    })
                  : []}
              </GlideSlider>
            </div>
          )
        })}
      </div>
    </>
  )
}

type Props = {
  models: (ImageType & {
    featuredImage: any
    uri: string
  })[][]
  indexToShow: number
  bannerTitles: string[]
}

export default Models
