/**
 * External dependencies
 */
import cx from 'classnames'
import Link from '../link'
import PropTypes from 'prop-types'

/**
 * Internal dependencies
 */
import { LinkIcon } from '../../icons'
import styles from './arrowLink.module.scss'

/**
 * ArrowLink component
 */
// TODO: we already have ExternalLink - this should be removed
const ArrowLink = ({
  className = '',
  link = '',
  linkText = '',
  icon = LinkIcon,
  isExternal = true
}) => {
  if (!link || !linkText) {
    return null
  }

  const ArrowIcon = icon
  return (
    <Link href={link} className={cx(styles['drive-arrow-link'], className)} isExternal={isExternal}>
      {isExternal ? (
        <>
          {linkText}
          <ArrowIcon className={cx(styles['drive-arrow-link__icon'])} />
        </>
      ) : (
        <a className={cx(styles['drive-arrow-link'], className)}>{linkText}</a>
      )}
    </Link>
  )
}

ArrowLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  icon: PropTypes.elementType
}

export default ArrowLink
