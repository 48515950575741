import cx from 'classnames'
import { FunctionComponent } from 'react'
import Link, { linkDefaultProps } from '../../link'
import styles from './tooltip.module.scss'

/**
 * Tooltip for Award tags in model cards
 * @returns FC
 */
const Tooltip: FunctionComponent<Props> = ({ name, categoryName, year, customClassname, uri }) => {
  let parsedWinnerType = name

  if (name === 'DCOTY Category Winner') {
    parsedWinnerType = 'Winner'
  }

  let topName = `${parsedWinnerType} - ${categoryName ?? ''}`

  if (name === 'DCOTY Overall Winner') {
    topName = 'Overall Winner'
  }

  return (
    <>
      {uri ? (
        <Link {...linkDefaultProps} href={uri}>
          <a className={cx(customClassname, 'tooltip')}>
            <h5>{topName}</h5>
            <h6>Drive Car of the Year {year ?? ''}</h6>
          </a>
        </Link>
      ) : (
        <span className={cx(customClassname, 'tooltip')}>
          <span className={styles['d-tooltip__hidden-clickable']}></span>
          <span className={styles['d-tooltip__text-wrapper']}>
            <h5>{topName}</h5>
            <h6>Drive Car of the Year {year ?? ''}</h6>
          </span>
        </span>
      )}
    </>
  )
}

type Props = {
  name: string | null
  categoryName: string
  year: string
  customClassname?: string
  uri?: string
}

export default Tooltip
