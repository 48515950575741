import PropTypes from 'prop-types'
import styles from './tooltip.module.scss'

const Tooltip = (props) => {
  const { tooltipClassName, tooltipTextClassName, text } = props
  return (
    <div className={styles[tooltipClassName]}>
      <p className={styles[tooltipTextClassName]}>{text}</p>
    </div>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipTextClassName: PropTypes.string
}

Tooltip.defaultProps = {
  text: '',
  tooltipClassName: 'tooltip',
  tooltipTextClassName: 'tooltip-text'
}

export default Tooltip
