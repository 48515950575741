import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { DealsDollarSignIcon } from '../../../icons'
import styles from './dealsInfo.module.scss'
import cx from 'classnames'

const DealsInfo = ({ deals, validDealsCount }) => {
  if ((!deals || isEmpty(deals)) && !validDealsCount && !deals?.length) {
    return null
  }

  const dealsCount = validDealsCount || deals.length

  return (
    <div className={cx(styles['drive-deals-info__container'])}>
      <div className={cx(styles['drive-deals-count'])}>{dealsCount} Current Deals</div>
      <span className={cx(styles['drive-deals-icon'])}>
        <DealsDollarSignIcon className='fill-current' />
      </span>
    </div>
  )
}

DealsInfo.propTypes = {
  deals: PropTypes.array,
  validDealsCount: PropTypes.number
}

DealsInfo.defaultProps = {
  deals: [],
  validDealsCount: 0
}

export default DealsInfo
