import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

/**
 * react-select options should have label and value fields
 * So we update objects to have label and value fields
 * @param {*} items
 * @returns items with label and value attributes
 */
export const getSelectOptionsFromData = (items = []) => {
  if (isEmpty(items) || !isArray(items)) {
    return items
  }
  return items.map((item) => ({
    ...item,
    label: item?.name,
    value: item?.slug
  }))
}
