import { DcotyFinalistIcon, Trophy } from '../../../icons'
import {
  DCOTY_OVERALL_WINNER_TERM_SLUG,
  DCOTY_CATEGORY_WINNER_TERM_SLUG
} from '../../../../constants/slug'
import { Award, AwardData } from '../../../../types/showrooms/awards'
import AwardComp from './Award'
import { FunctionComponent, useState } from 'react'
import { NamedIconProps } from '../../icon/types'
import styles from './awardIcon.module.scss'

/**
 * Renders award tags with tooltips
 * @props awardTagsSlug model's awardTags from WP
 * @returns FC
 */
const AwardIcons: FunctionComponent<Props> = ({ awardTagsSlug }) => {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null)

  if (!awardTagsSlug || !Array.isArray(awardTagsSlug)) {
    return null
  }

  // should be sorted by ASC on award year
  const sortedAwards = awardTagsSlug?.sort((award, award2) => award?.year - award2?.year)

  const toggleTooltip = (id: string) => {
    if (id === activeTooltip) {
      setActiveTooltip(null)
      return false
    }

    setActiveTooltip(id)
  }

  const renderAward = ({ data, year, key }: AwardProps) => {
    const { type = null, category = { name: '' }, id, post } = data ?? {}
    const { slug, name = null } = type ?? {}
    const { name: categoryName } = category ?? {}
    const { uri } = post ?? {}

    // type is like Finalist, Overall Winner, etc, if we don't have this, then no need to render the award and tooltip
    if (!type) {
      return null
    }

    const tooltipProps = { name, categoryName, year }
    // eslint-disable-next-line no-undef
    let icon: (props?: NamedIconProps) => JSX.Element = DcotyFinalistIcon

    if (slug && [DCOTY_OVERALL_WINNER_TERM_SLUG, DCOTY_CATEGORY_WINNER_TERM_SLUG].includes(slug)) {
      icon = Trophy
    }

    return (
      <AwardComp
        key={key}
        slug={slug ?? ''}
        toggleTooltip={toggleTooltip}
        activeTooltip={activeTooltip}
        id={id}
        tooltipProps={tooltipProps}
        iconComp={icon}
        uri={uri}
      />
    )
  }

  return (
    <div className={styles['drive-award-icons__wrapper']}>
      {sortedAwards?.map((award) => {
        return award?.award?.map((data, index) => {
          return renderAward({
            data,
            year: award?.year ? award?.year?.toString() : '',
            key: `${data?.id ?? index}`
          })
        })
      })}
    </div>
  )
}

type AwardProps = { data: AwardData; year: string; key: string }

type Props = {
  awardTagsSlug: Award[]
}

export default AwardIcons
