import cx from 'classnames'
import { FunctionComponent, useContext } from 'react'
import { FeaturedImage } from '../../../types/featuredImage'
import { PageContext } from '../../../utils/contexts/pageContext'
import Image from '../../common/image'
import Link, { linkDefaultProps } from '../../common/link'
import styles from './comparisonCta.module.scss'

/**
 * Comparison CTA with data coming from WP including the image
 * @param data coming from WP
 * @returns FunctionComponent
 */
const ComparisonCta: FunctionComponent<ComparisonCtaProps> = ({ data, wrapperClassname }) => {
  const { pageStore } = useContext(PageContext)
  const hasPageTakeOver = pageStore?.gam_oop_pos0?.img?.['data-bgcolor'] ?? false

  return (
    <div
      className={cx(styles['d-comparison-cta'], wrapperClassname ?? '', {
        'has-pto': hasPageTakeOver
      })}
    >
      <div className={styles['d-comparison-cta__wrapper']}>
        <div className={styles['d-comparison-cta__img-wrapper']}>
          <Image
            {...(data?.image ?? {})}
            width='360'
            height='278'
            altText='Compare Car Specs and prices'
            imageBg='bg-transparent'
            sizeName='SHOWROOMS_LANDING_COMPARISON_IMAGE'
          />
        </div>
        <div className={styles['d-comparison-cta__right-wrapper']}>
          <div className={styles['d-comparison-cta__text-wrapper']}>
            <h2>{data?.heading ?? ''}</h2>
            <p>{data?.subheading ?? ''}</p>
          </div>
          <Link {...linkDefaultProps} href={data?.cta?.url ?? '/compare-specs/'}>
            <a>{data?.cta?.title ?? 'Compare now'}</a>
          </Link>
        </div>
      </div>
    </div>
  )
}

export type ComparisonCtaProps = {
  data: {
    image: FeaturedImage
    heading: string
    subheading: string
    cta: {
      title: string
      url: string
    }
  }
  wrapperClassname?: string
}

export default ComparisonCta
