import cx from 'classnames'
import PropTypes from 'prop-types'
import Link from '../../common/link'
import Image from '../../common/image'
import isEmpty from 'lodash/isEmpty'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useReducer, useState } from 'react'
import Select, { createFilter } from 'react-select'
import { amendedComponents } from '@grille/components/common/react-select/amended-attribute-components'
import { getPopularMakes } from '../../../utils/functions/makes'
import { GET_LISTING_MODELS_AVAILABLE } from '../../../queries/cars-for-sale/listings/get-cars-for-sale-models'
import { sanitize } from '../../../utils/functions/miscellaneous'
import { getSelectOptionsFromData } from '../../../utils/functions/getSelectOptionsFromData'
import styles from './shortcutCardCfs.module.scss'
import orderBy from 'lodash/orderBy'

const modelDefaultLabel = 'Model'
const initialCarForSaleValues = {
  make: null,
  model: null,
  makeLabel: 'Select Make',
  modelLabel: modelDefaultLabel,
  modelsList: {}
}

/**
 * Reducer to manage car for sale
 *
 * @param {Object} state  Current data
 * @param {Object} action Current action
 *
 * @return {Object}
 */
const carForSaleReducer = (state, action) => {
  switch (action.type) {
    case 'MAKE':
      return {
        ...state,
        make: action.payload.value,
        makeLabel: action.payload.label,
        modelsList: {},
        modelLabel: modelDefaultLabel,
        model: null
      }
    case 'MODEL':
      return { ...state, model: action.payload.value, modelLabel: action.payload.label }
    case 'MODELS_LIST':
      return { ...state, modelsList: action.payload }
    default:
      throw new Error()
  }
}

/**
 * CarForSale Component.
 *
 * @param {String} { className } as props
 * @returns {JSX.Element}
 */
const CarForSaleCard = (props) => {
  const [carType, setCarType] = useState('all')
  const [state, dispatch] = useReducer(carForSaleReducer, initialCarForSaleValues)
  const handleCarForSaleUpdate = (type, label, value) => {
    dispatch({ type, payload: { label, value } })
  }

  const popularMakes = getPopularMakes(props?.makes?.options ?? [], true)

  /**
   * react-select options should have label and value fields
   * So we update objects to have label and value fields
   * We also add Group headers - Popular makes and All makes
   */
  const popularMakesOptions = getSelectOptionsFromData(popularMakes)
  const allMakesOptions = getSelectOptionsFromData(props?.makes?.options)

  const sortedPopularMakes = orderBy(
    popularMakesOptions,
    [(option) => option?.label.toLowerCase()],
    ['asc']
  )
  const sortedAllMakes = orderBy(
    allMakesOptions,
    [(option) => option?.label.toLowerCase()],
    ['asc']
  )

  const allMakeOptions = [
    {
      label: 'Popular Makes',
      options: sortedPopularMakes
    },
    {
      label: 'All Makes',
      options: sortedAllMakes
    }
  ]

  const allModelOptions = getSelectOptionsFromData(state?.modelsList?.options)
  const sortedAllModelOptions = orderBy(
    allModelOptions,
    [(option) => option?.label.toLowerCase()],
    ['asc']
  )
  const carTypes = [
    {
      value: '["new","demo"]',
      label: 'New'
    },
    {
      value: '["used"]',
      label: 'Used'
    },
    {
      value: 'all',
      label: 'All'
    }
  ]

  const [fetchModels] = useLazyQuery(GET_LISTING_MODELS_AVAILABLE, {
    ssr: false,
    notifyOnNetworkStatusChange: true,
    context: { listing: true },
    onCompleted: (data) => {
      dispatch({ type: 'MODELS_LIST', payload: data?.availableModels })
    }
  })

  useEffect(() => {
    if (state?.make) {
      fetchModels({
        variables: {
          where: JSON.stringify({ makeSlug: state?.make })
        }
      })
    }
  }, [fetchModels, state?.make])

  const getCarsForSaleURL = () => {
    const listingType = `${
      carType === 'all'
        ? '#listingType=["demo","used"]'
        : carType === '["new","demo"]'
        ? '#listingType=["demo"]'
        : ''
    }`
    const makeSlug = state?.make ?? undefined
    const modelSlug = state?.model ?? undefined
    let carsForSaleListingBaseURL

    if (makeSlug) {
      carsForSaleListingBaseURL =
        carType === '["new","demo"]'
          ? '/cars-for-sale/search/new/all/all'
          : carType === '["used"]'
          ? '/cars-for-sale/search/used/all/all'
          : '/cars-for-sale/search/new/all/all'
    } else {
      carsForSaleListingBaseURL =
        carType === '["new","demo"]'
          ? '/cars-for-sale/search/new'
          : carType === '["used"]'
          ? '/cars-for-sale/search/used'
          : '/cars-for-sale/search/new'
    }

    const url = '/cars-for-sale/search/'

    switch (true) {
      case !isEmpty(makeSlug) && !isEmpty(modelSlug):
        return `${carsForSaleListingBaseURL}/${makeSlug}/${modelSlug}/${listingType}`
      case !isEmpty(makeSlug):
        return `${carsForSaleListingBaseURL}/${makeSlug}/${listingType}`
      case carType !== 'all':
        return `${carsForSaleListingBaseURL}/${listingType}`
      default:
        return url
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      '&:hover': {
        borderColor: 'white'
      }
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      ':hover': {
        borderColor: 'white'
      }
    }),

    option: (provided, state = { isFocused: false }) => ({
      ...provided,
      backgroundColor: state.isFocused && '#F7F7F7'
    })
  }

  const formatGroupLabel = (data) => (
    <p className='sticky mt-1 mb-3 text-sm text-gray-400'>{data.label}</p>
  )

  return (
    <div className={cx([styles['drive-shortcut-card-cfs__wrapper'], props.className])}>
      <h3 className={cx(styles['drive-shortcut-card-cfs__wrapper__title'])}>
        <span dangerouslySetInnerHTML={{ __html: sanitize(props?.data?.heading) }} />
        &nbsp;
        <strong
          dangerouslySetInnerHTML={{ __html: sanitize(props?.data?.subHeading) }}
          className='font-semibold'
        />
      </h3>
      <div className={styles['drive-shortcut-card-cfs__wrapper__select-container']}>
        <Select
          instanceId={`cfs-shortcard-card-make`}
          placeholder={state?.makeLabel ? state?.makeLabel : 'Select Make'}
          components={{
            Input: amendedComponents.Input,
            option: amendedComponents.option,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
          className='m-react-select'
          classNamePrefix='m-react-select'
          styles={customStyles}
          formatGroupLabel={formatGroupLabel}
          isClearable
          options={allMakeOptions}
          isOptionDisabled={(option) => option?.disabled}
          onChange={(option) => handleCarForSaleUpdate('MAKE', option?.name, option?.slug)}
          filterOption={createFilter({ matchFrom: 'start' })}
          value={state?.make}
        />
        <Select
          instanceId={`cfs-shortcard-card-model`}
          placeholder={state?.modelLabel}
          components={{
            Input: amendedComponents.Input,
            option: amendedComponents.option,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
          className='mt-4 m-react-select'
          classNamePrefix='m-react-select'
          styles={customStyles}
          isClearable
          options={sortedAllModelOptions}
          isOptionDisabled={(option) => option?.disabled}
          onChange={(option) => handleCarForSaleUpdate('MODEL', option?.name, option?.slug)}
          filterOption={createFilter({ matchFrom: 'start' })}
          value={state?.model}
        />
        <div className={cx(styles['drive-shortcut-card-cfs__wrapper__filters-container'])}>
          {carTypes.map((type, index) => {
            return (
              <button
                key={index}
                onClick={() => setCarType(type.value)}
                className={cx(styles['drive-shortcut-card-cfs__wrapper__filter-option'], [
                  carType !== type.value
                    ? styles['drive-shortcut-card-cfs__wrapper__filters-container__blue-btn']
                    : styles['drive-shortcut-card-cfs__wrapper__filters-container__white-btn']
                ])}
              >
                {type.label}
              </button>
            )
          })}
        </div>
      </div>
      <div className={styles['drive-shortcut-card-cfs__wrapper__image_container']}>
        <Link href='/cars-for-sale/search/'>
          <a>
            <figure>
              <Image
                cloudinary={props?.data?.featuredImage?.cloudinary}
                sourceUrl={props?.data?.featuredImage?.sourceUrl}
                altText={props?.data?.featuredImage?.altText}
                caption={props?.data?.featuredImage?.caption}
                title={props?.data?.featuredImage?.title}
                sizeName='HOMEPAGE_SHOWROOM_PROMO'
                className='object-contain w-full'
                imageBg=''
              />
            </figure>
          </a>
        </Link>
      </div>
      <div className={styles['drive-shortcut-card-cfs__wrapper__button-container']}>
        <Link href={getCarsForSaleURL()} isJumpLinkOrHashFragment>
          <a className={styles['drive-shortcut-card-cfs__wrapper__button']}>Search Cars for Sale</a>
        </Link>
      </div>
    </div>
  )
}

CarForSaleCard.propTypes = {
  carTypes: PropTypes.array,
  className: PropTypes.string,
  makes: PropTypes.object
}

export default CarForSaleCard
